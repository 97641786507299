<template>
  <div>
    <v-card class="text-center pa-1" elevation="2">
      <v-card-title class="justify-center display-1 mb-6">
        <img alt="SE2A Logo" height="90" src="/img/logo_SE2A.png"/>
        <span style="margin-top: 11px; margin-left: 6px">Intranet</span>
      </v-card-title>
      <v-card-subtitle>{{ $t('auth.login.subtitle') }}</v-card-subtitle>

      <v-card v-if="isRelog" color="yellow lighten-4">
        <v-card-text>You got logged out automatically. Please login to continue using the intranet</v-card-text>
      </v-card>

      <!-- sign in form -->
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-text-field
            v-model="email"
            :error="error"
            :label="$t('auth.login.email')"
            :rules="[rules.email]"
            :validate-on-blur="false"
            name="email"
            outlined
            @change="resetErrors"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error="error"
            :error-messages="errorMessages"
            :label="$t('auth.login.password')"
            :rules="[rules.password]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            outlined
            @change="resetErrors"
            @click:append="showPassword = !showPassword"
            v-on:keyup.enter="submit"
          ></v-text-field>

          <v-btn
            :disabled="isSignInDisabled"
            :loading="isLoading"
            block
            color="primary"
            x-large
            @click="submit"
          >{{ $t('auth.login.button') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="text-center mt-6">
      <router-link to="/auth/forgot-password">
        {{ $t('auth.login.forgot') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {VERSION} from "@/config/version";

export default {
  name: "Login",
  data: function () {
    return {
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      // show password field
      showPassword: false,

      // input rules
      rules: {
        email: (value) => /^\S+@\S+\.\S+$/.test(value) || 'Please enter a valid e-mail address',
        password: (value) => (value && Boolean(value)) || 'Please enter your password'
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'isRelog']),
    version() {
      return VERSION;
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit() {
      if (this.$refs.form.validate()) {
        this.signIn()
      }
    },
    signIn() {
      this.isLoading = true;
      this.login({
        email: this.email,
        password: this.password
      }).then(async () => {
        this.isLoading = false;
        this.$router.push('/1/welcome').then(r => {}); // redirect to root unit
      }).catch(e => {
        this.error = true;
        this.errorMessages = e.message;
        this.isLoading = false;
      })
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
    }
  }
}
</script>

<style scoped>

</style>
