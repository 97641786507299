<template>
  <div>
    <v-card v-if="!submitted" class="text-center pa-1" elevation="2">
      <v-card-title class="justify-center display-1 mb-2">{{ $t('auth.forgot.title') }}</v-card-title>
      <v-card-subtitle>
        {{ $t('auth.forgot.subtitle') }}
      </v-card-subtitle>

      <!-- reset form -->
      <v-card-text>
        <v-form ref="form" v-model="isFormValid" lazy-validation @submit.prevent="submit">
          <v-text-field
            v-model="email"
            :error="error"
            :error-messages="errorMessages"
            :label="$t('auth.forgot.email')"
            :rules="[rules.required]"
            :validate-on-blur="false"
            name="email"
            outlined
            @change="resetErrors"
          ></v-text-field>

          <v-btn
            :loading="isLoading"
            block
            color="primary"
            x-large
            @click="submit"
            :disabled="!isValid"
          >{{ $t('auth.forgot.button') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else class="pa-1" elevation="2">
      <v-card-title class="justify-center">
        <span class="text-subtitle-1">{{ $t('auth.forgot.check-mail') }}</span>
      </v-card-title>
    </v-card>

    <div class="text-center mt-6">
      <router-link to="/auth/signin">
        {{ $t('auth.forgot.back-to-signin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,

      // reset button
      isLoading: false,

      // form
      isFormValid: true,
      email: '',

      // form error
      error: false,
      errorMessages: '',

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  computed: {
    isValid() {
      return /^\S+@\S+\.\S+$/.test(this.email);
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.$api.go('/api/reset_password/request').post({
          email: this.email
        }).then(() => {
          this.resetErrors();
          this.isLoading = false;
          this.submitted = true;
        }).catch(e => {
          this.isLoading = false;
          this.error = true;
          this.errorMessages = 'Please enter a valid e-mail address';
        });
      }
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
    }
  }
}
</script>
